// TODO
// $color-mode-type: media-query;

$yellow: #fffcdd;
$blue: #dcf7f3;
$red: #ffd8d8;

$primary: $yellow;
$secondary: $blue;
$danger: $red;

@import "bootstrap";
@import "@fortawesome/fontawesome-svg-core/styles";

.debug-message {
    display: none;
}

.debug {
    .debug-message {
        display: block;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    
    .wrapper-content {
        flex-grow: 1;
    }
}