.youtube-video {
    width: 100%;
    height: 100%;

    .youtube-video-thumbnail {
        width: 100%;
        height: 100%;
    }

    .youtube-video-icon {
        width: 80px;
    }
}