.baby-station {
    display: flex;
    flex-direction: column;
    height: 100%;

    .video {
        flex-grow: 1;
        object-fit: contain;
        max-height: calc(100vh - 150px);
        background-color: #000000;

        filter: grayscale(100%);

        &.active {
            filter: none;
        }
    }
}