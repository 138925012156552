.parent-station {
    display: flex;
    flex-direction: column;
    height: 100%;

    #button-start-recording,
    #button-stop-recording {
        display: none;
    }

    .video {
        display: none;
    }

    &.audio-and-video {
        #button-start-recording,
        #button-stop-recording {
            display: block;
            align-self: center;
        }

        .video {
            display: block;
            flex-grow: 1;
            object-fit: contain;
            max-height: calc(100vh - 250px);
            background-color: #000000;
        }
    }

    canvas.audio-visualiser {
        width: 100%;
        height: 30vh;
        margin-top: 20vh;
    }
}
