@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";

.initial-error-check {
    position: relative;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);

    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    min-width: 500px;
    border: {
        bottom: {
            left-radius: $border-radius;
            right-radius: $border-radius;
        }
    };
}
